import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import { Link } from "@remix-run/react";
import type { SerializeFrom } from "@remix-run/server-runtime";

import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { StarFilledIcon, StarIcon } from "@radix-ui/react-icons";

import { GlobalErrors } from "~/components/forms/GlobalErrors";
import { isValidationError } from "~/components/forms/validationErrorResponse";
import { HorizontalSlider } from "~/components/horizontal-slider";
import { ProductImage } from "~/components/product-list/product-image";
import VariantSelector from "~/components/products/product-variant-selector";
import { Alert, AlertTitle } from "~/components/ui/alert";
import { Button } from "~/components/ui/button";
import { Dialog, DialogContent } from "~/components/ui/dialog";
import { Label } from "~/components/ui/label";
import { TriButton } from "~/components/ui/tri-button";
import { useURL } from "~/contexts";
import * as gtag from "~/google-analytics/utils/gtags.client";
import useImageUtils from "~/hooks/useImageUtils";
import type {
  C_ProductInfo,
  ProductHitWithSwatches,
  ProductSwatch,
} from "~/lib/app.types";
import { LOW_STOCK_NUM } from "~/lib/constants";
import { useOnCompleted } from "~/lib/remix/fetcher";
import { cn } from "~/lib/ui";
import { useFetchProduct } from "~/routes/($locale)+/resources+/product.$productId";
import { getVariantImage } from "~/shop/utils/image";
import { useFormatPrice } from "~/shop/utils/price";
import { getSelectedVariant } from "~/shop/utils/variants";

import type { BagProduct } from "../../routes/($locale)+/resources+/basket";
import { useBasketActions } from "../../routes/($locale)+/resources+/basket";
import Image from "../ui/image";
import { SwatchCrossout } from "../ui/swatch-crossout";
import ProductQuantitySelector from "./product-quantity-selector";

export function ProductQuickViewDialog({
  open,
  children,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}) {
  return (
    <Dialog
      open={open}
      onOpenChange={open => {
        if (!open) onClose();
      }}
    >
      <DialogContent className="shadow-light-3 m-0 flex w-[312px] flex-col items-start self-stretch rounded-lg border border-neutralalpha-6 bg-page-background px-0 py-3 sm:w-[380px] md:w-[380px]">
        {children}
      </DialogContent>
    </Dialog>
  );
}

export const ProductQuickView = ({
  product,
  currentSwatch,
  fetcherData, // data of a previous fetcher that caused this view to appear
  onClose,
}: {
  product: ProductHitWithSwatches;
  currentSwatch: ProductSwatch;
  fetcherData: unknown;
  onClose: () => void;
}) => {
  const { getProduct, fetcher } = useFetchProduct();
  const { getImageLink } = useImageUtils();
  useEffect(() => {
    if (product.c_productInfo?.master?.masterId)
      getProduct(product.c_productInfo?.master?.masterId);
  }, [product.c_productInfo?.master?.masterId]);
  const master = fetcher.data?.data;
  const defaultSize =
    product.c_productInfo?.variationAttributes?.find(x => x.id === "size")
      ?.values?.[0]?.value || "";

  const [selected, setSelected] = useState<{
    [key: string]: string;
  }>({
    ...(product.c_productInfo?.variationValues as
      | Record<string, string>
      | undefined),
    ...currentSwatch.variationGroup?.variationValues,
    size: defaultSize,
  });
  const [variantError, setVariantError] = useState<boolean>(false);
  const [productQuantity, setProductQuantity] = useState<string>("1");

  const basket = useBasketActions();
  const formatPrice = useFormatPrice();
  const url = useURL();

  useOnCompleted(data => {
    if (isValidationError(data)) {
      return;
    }
    //happy to close the dialog
    onClose();
  }, basket.fetcher);

  const addGtagEvent = () => {
    /* For GA4 */
    gtag.event({
      action: "add_to_bag_clicked",
      category: "product_quick_view",
      label: "added_product_id",
      value: product?.productId,
    });
  };

  if (!product.c_productInfo) return null;

  return (
    <div className="flex w-full flex-col items-start gap-3 self-stretch pr-px">
      <VariantSelector.Selector
        master={master}
        product={product.c_productInfo as SerializeFrom<C_ProductInfo>}
        productId={product.productId}
        onChange={setSelected}
        defaultValue={selected}
      >
        <VariantSelector.OptionSelect type="color">
          {({ selected: selectedOption, attribute }) => (
            <>
              <div className="flex flex-row items-start gap-2 self-stretch px-3 py-0">
                <ProductImage
                  image={getVariantImage(product.c_productInfo!, selected)?.[0]}
                  type="plp"
                  className="h-s-product-card-height w-20 rounded-xl"
                  small
                />
                <div className="flex flex-1-0-0 flex-col items-start gap-0.5 pb-0 pl-0 pr-9 pt-1.5">
                  <h1 className="text-[14px] font-semibold leading-5 tracking-tight text-text-dark-on-light">
                    {product?.productName}
                  </h1>
                  <h1 className="text-xs font-light tracking-tight text-text-dark-on-light">
                    {master ? (
                      <VariantSelector.SelectedVariant>
                        {({ salePrice, currentPrice, variant }) => {
                          return (
                            <>
                              {salePrice ? (
                                <>
                                  <span className="line-through">
                                    {formatPrice(currentPrice)}
                                  </span>
                                  <span className="pl-1 text-salePrice">
                                    {formatPrice(salePrice)}
                                  </span>
                                </>
                              ) : (
                                <>
                                  {formatPrice(currentPrice ?? product.price)}
                                </>
                              )}
                            </>
                          );
                        }}
                      </VariantSelector.SelectedVariant>
                    ) : (
                      formatPrice(product.price)
                    )}
                  </h1>
                  <div className="flex items-center" itemProp="aggregateRating">
                    {[0, 1, 2, 3, 4].map(rating =>
                      Math.round(
                        parseFloat(
                          product?.c_productInfo?.c_bvAverageRating || "0",
                        ),
                      ) > rating ? (
                        <StarFilledIcon
                          key={rating}
                          className={cn(
                            "h-3 w-3 flex-shrink-0 text-text-dark-on-light",
                          )}
                          aria-hidden="true"
                        />
                      ) : (
                        <StarIcon
                          key={rating}
                          className={cn(
                            "h-3 w-3 flex-shrink-0 text-text-dark-on-light",
                          )}
                          aria-hidden="true"
                        />
                      ),
                    )}
                  </div>
                  <Link
                    to={url(`/${product.productId}.html`)}
                    onClick={() => onClose()}
                    prefetch="intent"
                    className="text-xs font-normal tracking-tight text-accentalpha-11"
                  >
                    View more details
                  </Link>
                </div>
              </div>
              <div className="flex flex-col items-start gap-1.5 self-stretch px-3 py-0">
                <Label className="text-sm font-semibold">
                  {attribute.name || attribute.id}:{" "}
                  <span className="font-light">
                    {
                      product.c_swatches?.find(
                        swatch => swatch.color.value === selectedOption,
                      )?.color.name
                    }
                  </span>
                </Label>
                <HorizontalSlider controlsEnabled>
                  <VariantSelector.OptionValues>
                    {({ option, setSelected, swatch, variationGroup }) => (
                      <span
                        aria-hidden="true"
                        key={`${option.name}-${option.value}`}
                        onClick={() => {
                          const selectedSwatch = product.c_swatches?.find(
                            swatch => swatch.color.value === option.value,
                          );
                          setSelected(option.value);
                        }}
                        className={cn(
                          option.name,
                          "relative flex h-10 w-10 cursor-pointer items-center justify-center rounded-full p-1",
                          selectedOption === swatch?.color.value &&
                            "border border-accent-11",
                          variationGroup?.orderable === true
                            ? ""
                            : "opacity-50",
                        )}
                      >
                        {swatch &&
                          swatch?.images?.swatch?.images?.[0]?.link && (
                            <Image
                              src={getImageLink(
                                swatch.images.swatch.images?.[0]?.link,
                              )}
                              alt={swatch?.name}
                              className="h-full w-full rounded-full object-cover"
                            />
                          )}
                        {!variationGroup?.orderable && (
                          <SwatchCrossout className="absolute h-10 w-10" />
                        )}
                      </span>
                    )}
                  </VariantSelector.OptionValues>
                </HorizontalSlider>
              </div>
            </>
          )}
        </VariantSelector.OptionSelect>
        <VariantSelector.OptionSelect type="size">
          {({ selected, attribute, allSelected }) => (
            <div className="flex w-full flex-col items-start gap-1.5 self-stretch px-3 py-0">
              <Label className="text-sm font-semibold">
                {attribute.name || attribute.id}:{" "}
                <span className="font-light">
                  {selected && selected.toLocaleUpperCase()}{" "}
                </span>
                {Boolean(
                  product.c_productInfo?.inventory?.stockLevel &&
                    product.c_productInfo?.inventory?.stockLevel <
                      LOW_STOCK_NUM,
                ) && (
                  <span className="rounded-[3px] border border-accentalpha-7 bg-surface-accent-light px-2 text-accentalpha-11">
                    low stock
                  </span>
                )}
              </Label>
              <HorizontalSlider controlsEnabled>
                <VariantSelector.OptionValues>
                  {({ option, setSelected, selectedValue }) => {
                    if (!master) {
                      return (
                        <Button
                          variant="ghost"
                          key={`${option.name}-${option.value}`}
                          className={cn(
                            "inline-block h-7 border border-neutral-3 bg-neutral-1 text-neutral-11 drop-shadow-sm [&:hover]:border-accent-11 [&:hover]:bg-panel-translucent",
                            selected === option.value &&
                              "border border-accent-11 bg-accentalpha-10 text-text-light-on-dark [&:hover]:border-accent-11 [&:hover]:bg-accentalpha-10 [&:hover]:text-text-light-on-dark",
                          )}
                          onClick={() => setSelected(option.value)}
                        >
                          {option.name}
                        </Button>
                      );
                    }
                    const variant = getSelectedVariant(master, {
                      ...allSelected,
                      size: option.value,
                    });

                    return (
                      <Button
                        variant="ghost"
                        key={`${option.name}-${option.value}`}
                        className={cn(
                          "inline-block h-7 border border-neutral-3 bg-neutral-1 text-neutral-11 drop-shadow-sm [&:hover]:border-accent-11 [&:hover]:bg-panel-translucent",
                          selected === option.value &&
                            "border border-accent-11 bg-accentalpha-10 text-text-light-on-dark [&:hover]:border-accent-11 [&:hover]:bg-accentalpha-10 [&:hover]:text-text-light-on-dark",
                          !variant?.orderable && "opacity-50",
                        )}
                        onClick={() => setSelected(option.value)}
                      >
                        {option.name}
                      </Button>
                    );
                  }}
                </VariantSelector.OptionValues>
              </HorizontalSlider>
            </div>
          )}
        </VariantSelector.OptionSelect>
        <VariantSelector.OptionSelect type="leg-length">
          {({ selected, attribute }) => (
            <div className="flex w-full flex-col items-start gap-1.5 self-stretch px-3 py-0">
              <Label className="text-sm font-semibold">
                {attribute.name || attribute.id}:{" "}
                <span className="font-light">{selected}</span>
              </Label>
              <HorizontalSlider controlsEnabled>
                <VariantSelector.OptionValues>
                  {({ option, setSelected, variationGroup }) => (
                    <Button
                      variant="outline"
                      key={`${option.name}-${option.value}`}
                      className={cn(
                        "inline-block h-7 border border-neutral-3 bg-neutral-1 text-neutral-11 drop-shadow-sm [&:hover]:border-accent-11 [&:hover]:bg-panel-translucent",
                        selected === option.value &&
                          "border border-accent-11 bg-accentalpha-10 text-text-light-on-dark [&:hover]:border-accent-11 [&:hover]:bg-accentalpha-10 [&:hover]:text-text-light-on-dark",
                        variationGroup?.orderable === true ? "" : "opacity-50",
                      )}
                      onClick={() => setSelected(option.value)}
                    >
                      {option.name}
                    </Button>
                  )}
                </VariantSelector.OptionValues>
              </HorizontalSlider>
            </div>
          )}
        </VariantSelector.OptionSelect>

        {variantError && (
          <div className="w-full px-3">
            <Alert className="mr-3" variant="error">
              <ExclamationTriangleIcon className="h-4 w-4" />
              <AlertTitle>Select all product attributes</AlertTitle>
            </Alert>
          </div>
        )}

        {Boolean(basket.fetcher.data || fetcherData) &&
          basket.fetcher.state !== "submitting" && (
            <div className="w-full px-3">
              <GlobalErrors data={basket.fetcher.data || fetcherData} />
            </div>
          )}

        <div className="flex items-start gap-4 self-stretch px-3 py-1.5">
          <ProductQuantitySelector
            productQuantity={productQuantity}
            setProductQuantity={setProductQuantity}
          />
          {master ? (
            <VariantSelector.SelectedVariant>
              {({ salePrice, currentPrice, variant }) => {
                return (
                  <TriButton
                    type={"button"}
                    variant="default"
                    className="flex h-7 flex-1-0-0 flex-row items-center"
                    isLoading={basket.fetcher.state !== "idle"}
                    disabled={variant?.orderable === false}
                    onClick={() => {
                      if (!variant) {
                        setVariantError(true);
                        return;
                      }
                      if (selected) {
                        const bagProduct = {
                          productId: variant.productId,
                          ...selected,
                        } as BagProduct;
                        basket.add(bagProduct, parseInt(productQuantity));
                        addGtagEvent();
                        setVariantError(false);
                      } else {
                        setVariantError(true);
                      }
                    }}
                  >
                    {variant?.orderable === false
                      ? "Out of stock"
                      : "Add to bag"}
                  </TriButton>
                );
              }}
            </VariantSelector.SelectedVariant>
          ) : (
            <TriButton
              type="button"
              variant="default"
              className="flex h-7 flex-1-0-0 flex-row items-center"
              isLoading={true}
              disabled={true}
            >
              Add to bag
            </TriButton>
          )}
        </div>
      </VariantSelector.Selector>
    </div>
  );
};
