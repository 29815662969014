import type { Product, VariationAttribute } from "~/lib/app.types";

export function getSelectedVariant(
  master: Product,
  selected: { [key: string]: string | undefined },
) {
  const allAttributes = master.variationAttributes?.length;
  return master.variants?.find(
    variant =>
      Object.keys(selected).length === allAttributes &&
      Object.keys(selected).every(
        key => variant?.variationValues?.[key] === selected[key],
      ),
  );
}

// Checks whether all variation attributes in that have more than one possible value have a selected value
export function validateVariationAttributes(
  selected: { [key: string]: string | undefined } | undefined,
  variationAttributes: Array<VariationAttribute> = [],
) {
  for (const { values, id } of variationAttributes) {
    if (
      values &&
      values?.length > 1 &&
      id &&
      selected &&
      selected[id] === undefined
    ) {
      return false;
    }
  }

  return true;
}
