import type { ProductSwatch } from "../products/productSearch.server";

import type { ImageGroup, Product } from "~/lib/app.types";
import { getImageGroup } from "~/shop/utils/image";

export const getSwatches = (
  product?: Product & { c_imageGroups?: ImageGroup[] },
): ProductSwatch[] => {
  const colorAttributes = product?.variationAttributes?.find(
    v => v.id === "color",
  )?.values;

  return (
    colorAttributes?.map(color => {
      // Update the variationValues with the current color, but make sure to keep other attributes, such as `leg-length`
      const variationValues: { [key: string]: string } = {
        ...product?.variationValues,
        color: color.value,
      };

      return {
        name: color.name || color.value,
        color,
        images: {
          swatch: getImageGroup(
            product?.c_imageGroups,
            "swatch",
            variationValues,
          ),

          large: getImageGroup(
            product?.c_imageGroups,
            "large",
            variationValues,
          ),
        },
        variationGroup: product?.variationGroups?.find(group => {
          return Object.entries(group.variationValues).every(
            ([key, value]) => variationValues[key] === value,
          );
        }),
      };
    }) ?? []
  );
};
