export const SwatchCrossout = ({ className }: { className?: string }) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="8.78278"
        y="4.54013"
        width="29.1461"
        height="6"
        transform="rotate(45 8.78278 4.54013)"
        fill="#F9F9FB"
      />
      <path
        d="M6.93707 5.81219L28.1877 27.0628L27.063 28.1875L5.81241 6.93686L6.93707 5.81219Z"
        fill="#E6E6E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 31.5C25.0081 31.5 31.5 25.0081 31.5 17C31.5 8.99187 25.0081 2.5 17 2.5C8.99187 2.5 2.5 8.99187 2.5 17C2.5 25.0081 8.99187 31.5 17 31.5ZM17 33C25.8366 33 33 25.8366 33 17C33 8.16344 25.8366 1 17 1C8.16344 1 1 8.16344 1 17C1 25.8366 8.16344 33 17 33Z"
        fill="#E6E6E9"
      />
    </svg>
  );
};
