import { CaretDownIcon } from "@radix-ui/react-icons";

import { MAX_ORDER_QUANTITY } from "~/lib/constants";

import { Select, SelectContent, SelectItem, SelectTrigger } from "../ui/select";

const getQuantityOptions = () => {
  const options = [];
  for (let i = 1; i <= MAX_ORDER_QUANTITY; i++) {
    options.push(
      <SelectItem key={i} value={i.toString()}>
        {i}
      </SelectItem>,
    );
  }
  return options;
};

const ProductQuantitySelector = ({
  setProductQuantity,
  productQuantity,
}: {
  productQuantity?: string;
  setProductQuantity: (quantity: string) => void;
}) => {
  return (
    <Select
      name="quantity"
      defaultValue="1"
      onValueChange={value => setProductQuantity(value)}
    >
      <SelectTrigger className="flex h-7 w-[50px] flex-col items-start bg-transparent">
        <span className="flex w-full flex-row leading-3">
          {productQuantity}
          <CaretDownIcon height={24} />
        </span>
      </SelectTrigger>
      <SelectContent position="popper" className="bg-white">
        {getQuantityOptions()}
      </SelectContent>
    </Select>
  );
};

export default ProductQuantitySelector;
