import { useEffect, useRef, useState } from "react";

import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";

import { cn } from "~/lib/ui";

type Props = {
  children: React.ReactNode;
  controlsEnabled?: boolean;
  className?: string;
  containerClassName?: string;
  shouldBeTransparent?: boolean;
};

const SCROLL_BY_AMOUNT = 100;
const ARROW_ICON_SIZE = 22;

export const HorizontalSlider = ({
  children,
  controlsEnabled,
  className,
  containerClassName,
  shouldBeTransparent,
}: Props) => {
  const content = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const [scrollToEndHiddenRight, setScrollToEndHiddenRight] =
    useState<boolean>(true);
  const [scrollToEndHiddenLeft, setScrollToEndHiddenLeft] =
    useState<boolean>(true);
  const contentWidth = content.current?.clientWidth || 0;
  const containerWidth = container.current?.clientWidth || 0;

  useEffect(() => {
    if (contentWidth > containerWidth) setScrollToEndHiddenRight(false);
  }, [contentWidth, containerWidth]);

  const handleScrollAction = (left?: boolean) => {
    const direction = left ? 1 : -1;
    container.current?.scrollBy({
      left: direction * SCROLL_BY_AMOUNT,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const {
      scrollWidth = 0,
      offsetWidth = 0,
      scrollLeft = 0,
    } = container.current || {};

    setScrollToEndHiddenLeft(scrollLeft < 1);
    setScrollToEndHiddenRight(
      offsetWidth + Math.ceil(scrollLeft) >= scrollWidth,
    );
  };

  return (
    <div className={cn("flex flex-col items-start self-stretch", className)}>
      <div
        ref={container}
        className={cn(
          "flex-[1 0 0] track no-scrollbar flex h-full items-center justify-start self-stretch overflow-x-auto overflow-y-hidden",
          containerClassName,
        )}
        onScroll={() => handleScroll()}
      >
        <div ref={content} className="flex items-start gap-1 whitespace-nowrap">
          {children}
        </div>
        <button
          hidden={scrollToEndHiddenRight || !controlsEnabled}
          className={cn(
            "absolute right-0 h-10 w-[50px] cursor-pointer",
            !shouldBeTransparent &&
              "bg-gradient-to-l from-panel-translucent via-panel-translucent to-transparent",
          )}
          onClick={() => handleScrollAction(true)}
        >
          <CaretRightIcon
            className="absolute right-2 top-[9px]"
            width={ARROW_ICON_SIZE}
            height={ARROW_ICON_SIZE}
          />
        </button>
        <button
          hidden={scrollToEndHiddenLeft || !controlsEnabled}
          className={cn(
            "absolute left-0 h-10 w-[50px] cursor-pointer",
            !shouldBeTransparent &&
              "bg-gradient-to-r from-page-background via-page-background to-transparent",
          )}
          onClick={() => handleScrollAction()}
        >
          <CaretLeftIcon
            className="absolute left-2 top-[9px]"
            width={ARROW_ICON_SIZE}
            height={ARROW_ICON_SIZE}
          />
        </button>
      </div>
    </div>
  );
};
