import type { ReactNode } from "react";
import {
  Suspense,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Await, useSearchParams } from "@remix-run/react";
import type { SerializeFrom } from "@remix-run/server-runtime";

import { Slot } from "@radix-ui/react-slot";

import { useURL } from "~/contexts";
import type {
  ImageGroup,
  Product,
  ProductSwatch,
  VariationAttribute,
  VariationAttributeValue,
  VariationGroup,
} from "~/lib/app.types";
import { invariant } from "~/lib/utils/utils";
import { getSwatches } from "~/shop/utils/swatches";
import { getSelectedVariant } from "~/shop/utils/variants";

const VariantContext = createContext<{
  productId: string;
  master?: Promise<SerializeFrom<Product>> | SerializeFrom<Product>;
  product: SerializeFrom<{
    variationAttributes?: Array<VariationAttribute>;
    variationGroups?: Array<VariationGroup>;
  }>;
  selected: { [key: string]: string };
  setSelected: (type: string, value: string) => void;
  swatches?: ProductSwatch[];
}>(undefined as any);
const useVariantContext = () => {
  const context = useContext(VariantContext);
  if (!context) {
    throw new Error(
      "VariantContext must be used within a VariantContextProvider",
    );
  }
  return context;
};

const Selector = ({
  product,
  master,
  productId,
  children,
  onSelect,
  onChange,
  defaultValue,
}: {
  productId: string;
  master?: Promise<SerializeFrom<Product>> | SerializeFrom<Product>;
  onChange?: (value: { [key: string]: string }) => void;
  defaultValue?: { [key: string]: string };
  product: SerializeFrom<
    Product & {
      variationAttributes?: Array<VariationAttribute>;
      variationGroups?: Array<VariationGroup>;
      c_imageGroups?: ImageGroup[];
    }
  >;
  onSelect?: (type: string, value: string) => void;
  children: ReactNode;
}) => {
  const [selected, setSelectedState] = useState<{ [key: string]: string }>(
    defaultValue ?? {},
  );
  useEffect(() => {
    if (onChange) onChange(selected);
  }, [selected]);

  const setSelected = useCallback(
    (type: string, value: string) => {
      setSelectedState(prev => ({ ...prev, [type]: value }));
      onSelect?.(type, value);
    },
    [onSelect],
  );
  const swatches = useMemo(() => getSwatches(product), [product]);

  return (
    <VariantContext.Provider
      value={{
        product,
        productId,
        selected,
        setSelected,
        swatches,
        master,
      }}
    >
      {children}
    </VariantContext.Provider>
  );
};
const VariantOptionContext = createContext<{
  type: string;
  options: VariationAttributeValue[];
  selectedValue: string;
  setSelected: (value: string) => void;
}>(undefined as any);
const useVariantOptionContext = () => {
  const context = useContext(VariantOptionContext);
  if (!context) {
    throw new Error(
      "VariantOptionContext must be used within a VariantOptionContextProvider",
    );
  }
  return context;
};
const OptionSelect = ({
  type,
  children,
}: {
  type: string;
  children: (props: {
    attribute: VariationAttribute;
    selected: string;
    allSelected: { [key: string]: string };
  }) => ReactNode;
}) => {
  const { product, selected, setSelected } = useVariantContext();
  const selectedValue = selected[type];
  const [attribute, options] = useMemo(
    () => [
      product.variationAttributes?.find(attr => attr.id === type),
      product.variationAttributes?.find(attr => attr.id === type)?.values ?? [],
    ],
    [product, type, selected],
  );

  if (!attribute) {
    return null;
  }
  return (
    <VariantOptionContext.Provider
      value={{
        type,
        options,
        selectedValue,

        setSelected: (value: string) => setSelected(type, value),
      }}
    >
      {options.length > 0
        ? children({
            attribute,
            selected: selectedValue,
            allSelected: selected,
          })
        : null}
    </VariantOptionContext.Provider>
  );
};
const OptionValues = ({
  children,
  asChild,
}: {
  asChild?: boolean;
  children: (props: {
    url: string;
    option: VariationAttributeValue;
    selectedValue: string;
    setSelected: (value: string) => void;
    swatch?: ProductSwatch;
    variationGroup: VariationGroup | undefined;
  }) => ReactNode;
}) => {
  const context = useVariantOptionContext();
  const { selected, productId, swatches, product } = useVariantContext();

  const getUrl = useURL();
  const [params] = useSearchParams();
  const { type } = context;
  const getSwatch = useCallback(
    (option: VariationAttributeValue) =>
      swatches?.find(s => s.color.value === option.value),
    [swatches, type],
  );

  const url = useCallback(
    (value: string) => {
      const searchParams = new URLSearchParams({
        ...Object.fromEntries(params.entries()),
        ...selected,
        [context.type]: value,
      });
      return getUrl(
        productId +
          `.html${
            // searchParams.size is null in Safari in older then  v17 versions
            // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/size
            searchParams.keys().next().value ? "?" : ""
          }` +
          searchParams.toString(),
      );
    },
    [selected, params, getUrl, productId, context.type],
  );

  return context.options.map((option, i) => {
    const groupSelection = { ...selected, [type]: option.value };
    const variationGroup = product.variationGroups?.find(group =>
      Object.entries(group.variationValues).every(
        ([key, value]) => groupSelection[key] === value,
      ),
    );
    return asChild ? (
      <Slot
        key={`${option.value}-${i}`}
        onClick={() => context.setSelected(option.value)}
      >
        {children({
          url: url(option.value),
          option,
          selectedValue: context.selectedValue,
          setSelected: context.setSelected,
          swatch: getSwatch(option),
          variationGroup,
        })}
      </Slot>
    ) : (
      children({
        url: url(option.value),
        option,
        selectedValue: context.selectedValue,
        setSelected: context.setSelected,
        swatch: getSwatch(option),
        variationGroup,
      })
    );
  });
};

const SelectedVariant = ({
  children,
}: {
  children: ({
    salePrice,
    currentPrice,
    variant,
  }:
    | {
        salePrice: number | undefined;
        currentPrice: number | undefined;
        variant?: { productId: string; orderable?: boolean | undefined };
        selected: { [key: string]: string };
        allAttributesSelected: boolean;
      }
    | {
        salePrice?: undefined;
        currentPrice?: undefined;
        variant?: undefined;
        selected: { [key: string]: string };
        allAttributesSelected: boolean;
      }) => React.ReactNode;
}) => {
  const { master, selected } = useVariantContext();
  invariant(master, "Cannot use SelectedVariant without master product");
  return (
    <Suspense fallback={children({ selected, allAttributesSelected: false })}>
      <Await resolve={master}>
        {master => {
          const allAttributes = master.variationAttributes?.length;
          const variationGroup = master.variationGroups?.find(
            variant => variant.variationValues.color === selected.color,
          );
          const variant = getSelectedVariant(master, selected);
          const currentVariantId = variationGroup?.productId;
          const price = variant?.price || master.price;
          let currentPrice = variant?.price || master.price;
          const maxPrice = master.priceMax;
          if (currentPrice && maxPrice && currentPrice < maxPrice) {
            currentPrice = maxPrice;
          }
          const masterSalePrice = currentVariantId
            ? master.c_saleprice?.[currentVariantId]
            : null;

          let salePrice = currentVariantId
            ? masterSalePrice && typeof masterSalePrice === "number"
              ? masterSalePrice
              : undefined
            : undefined;

          if (
            masterSalePrice &&
            typeof masterSalePrice === "object" &&
            masterSalePrice.salesprice
          ) {
            salePrice = masterSalePrice.salesprice;
          }

          if (!salePrice && price && maxPrice && maxPrice > price) {
            salePrice = price;
          }

          return children({
            salePrice,
            currentPrice,
            variant,
            selected,
            allAttributesSelected:
              allAttributes === Object.keys(selected).length,
          });
        }}
      </Await>
    </Suspense>
  );
};

/**
 * Usage:
 * ```tsx
 * <VariantSelector.Selector product={product}>
 * <VariantSelector.OptionSelect type="color">
 *     {({ attribute, selected }) => (
 *     <VariantSelector.OptionValues>
 *         {({ option, selectedValue, setSelected }) => null}
 *     </VariantSelector.OptionValues>
 *     )}
 * </VariantSelector.OptionSelect>
 * ..... other selectors
 * <VariantSelector.SelectedVariant>
 *    // variant will be undefined until all options are selected
 *    {({ salePrice, currentPrice, variant }) => null}
 * </VariantSelector.SelectedVariant>
 * </VariantSelector.Selector>
 *```
 */
export const VariantSelector = {
  Selector,
  /** Renders children only if option exist */
  OptionSelect,
  OptionValues,
  SelectedVariant,
};

export default VariantSelector;
