import { useCallback } from "react";

import { useFetcher } from "@remix-run/react";
import { defer } from "@remix-run/server-runtime";

import { invariantResponseError } from "~/components/forms/validationErrorResponse.server";
import { SWR } from "~/server/caching.server";

import { useURL } from "~/contexts";

export const loader = async ({ request, context, params }: LoaderArgs) => {
  const $productId = Array.isArray(params.productId)
    ? params.productId?.[0]
    : params.productId;

  invariantResponseError($productId, "Not found");
  const swr = SWR(`resource-product${$productId}`);
  const { api } = context;

  try {
    /* fetching with useFetcher always resolves promises in fetcher.data result */
    const data = await swr(
      () =>
        api.shopperProducts.getProduct({
          parameters: {
            allImages: true,
            id: $productId,
          },
          headers: { [api.API_CACHE_HEADER]: "max-age=3600" },
        }),
      [$productId],
    );

    return defer({ data });
  } catch (error) {
    console.error(error);
    throw new Response("Not Found", { status: 404 });
  }
};

export const useFetchProduct = () => {
  const fetcher = useFetcher<typeof loader>();
  const url = useURL();

  const getProduct = useCallback(
    (productId: string) => {
      fetcher.load(url(`/resources/product/${productId}`));
    },
    [fetcher],
  );

  return { getProduct, fetcher };
};
